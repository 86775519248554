import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute,RouterLink } from '@angular/router'; 

@Component({
  selector: 'app-stat',
  templateUrl: './stat.component.html',
  styleUrls: ['./stat.component.scss']
})
export class StatComponent implements OnInit {

  @Input() title: string;
  @Input() invalue: string;
  @Input() outvalue: string;
  @Input() totvalue: string;
  @Input() icon: string;
  @Input() eventid: number;
  @Input() categoryid: number;

  constructor() { }

  ngOnInit(): void {
  }

}
