import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;


  validationform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  eventlist = [];
  logoimg: any;
  logoimgsrc: any;
  eventcode: any;
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private eventService: EventservService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private tokenStorage: TokenStorageService) {
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;
     }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Event', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      EventCode: ['', { disabled: true }],
      EventName: ['', [Validators.required]],
      EventId: [0],
      Venue: ['', [Validators.required]],
      MobileNo: ['', [Validators.required]],
      Orgenizer: ['', [Validators.required]],
      DateofEvent: ['', [Validators.required]],
      StartTime: ['', [Validators.required]],
      EndTime: ['', [Validators.required]],
      Location: ['', [Validators.required]],
      EventImage: [''],
      Password: ['', [Validators.required]],
      IsActive: [true],
      CreatedBy: [this.userflag.id]
    });
    this.validationform.controls['EventCode'].disable();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }

  cleartext() {
    this.validationform.setValue({
      EventCode: ['', { disabled: true }],
      EventName: ['', [Validators.required]],
      EventId: ['', [Validators.required]],
      Venue: ['', [Validators.required]],
      MobileNo: ['', [Validators.required]],
      Orgenizer: ['', [Validators.required]],
      DateofEvent: ['', [Validators.required]],
      StartTime: ['', [Validators.required]],
      EndTime: ['', [Validators.required]],
      Location: ['', [Validators.required]],
      EventImage: [''],
      Password: ['', [Validators.required]],
      IsActive: [true],
      CreatedBy: [this.userflag.id]
    });
    this.validationform.controls['EventCode'].disable();
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    this.eventService.geteventcode().subscribe(
      (data: any) => {
        if (data != null && data != undefined && data != '') {
          console.log(data);
          if (data[0].EventCode != null && data[0].EventCode != undefined && data[0].EventCode != '') {
            let val = parseInt(data[0].EventCode) + 1;
            this.validationform.controls['EventCode'].setValue('EVT#' + val);
            this.eventcode = 'EVT#'+val;
          }
          else {
            this.validationform.controls['EventCode'].setValue('EVT#1');
            this.eventcode = 'EVT#1';
          }
        }
        else {
          this.validationform.controls['EventCode'].setValue('EVT#1');
          this.eventcode = 'EVT#1';
        };
      },
      (err: any) => {

      }
    );

    this.eventService.getevent().subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      this.eventService.addevent(this.validationform.value,this.eventcode, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.validationform.controls['EventCode'].disable();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.eventService.updateevent(this.validationform.value,this.eventcode, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.validationform.controls['EventCode'].disable();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.eventService.findeventById(e.row.data.EventId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        this.validationform.setValue({
          EventName: f.EventName,
          EventId: f.EventId,
          EventCode: f.EventCode,
          Venue: f.Venue,
          MobileNo: f.MobileNo,
          Orgenizer: f.Orgenizer,
          Location: f.Location,
          DateofEvent: f.DateofEvent,
          StartTime: f.StartTime,
          EndTime: f.EndTime,
          IsActive: f.IsActive == 'Yes' ? true : false,
          CreatedBy: this.userflag.id,
          Password: f.Password,
          EventImage: ''
        });
        this.logoimg = f.EventImage;
        this.logoimgsrc = f.EventImg == '' ? '' : this.sanitizer.bypassSecurityTrustUrl('https://eventapi.event.airconinfotech.com/uploads/event/' + f.EventImage);
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.eventService.deleteevent(e.row.data.EventId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'EventList.xlsx');
      });
    });
    e.cancel = true;
  }


}
