<div class="container-fluid">
    <app-pagetitle title="BackStage" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Entry</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">

                                            <div class="row">
                                                <!-- <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Event</label>
                                                        <select class="form-control" id="validationCustom02" formControlName="EventId" [ngClass]="{'is-invalid': submit && form.EventId.errors}">
                                                            <option *ngFor="let cat of eventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                                                          </select>

                                                        <div *ngIf="submit && form.EventId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventId.errors.required">Please select an
                                                                Event.</span>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Guest</label>
                                                        <!-- <select class="form-control" id="validationCustom03" formControlName="GuestId" [ngClass]="{'is-invalid': submit && form.GuestId.errors}">
                                                            <option *ngFor="let cat of guestlist" [value]="cat.GuestId">{{cat.Name}}</option>
                                                          </select> -->
                                                        <ng-select formControlName="GuestId" [ngClass]="{ 'is-invalid': submit && form.GuestId.errors}">
                                                            <ng-option *ngFor="let car of guestlist" [value]="car.GuestId">{{car.Name}}</ng-option>
                                                        </ng-select>
                                                        <div *ngIf="submit && form.GuestId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.GuestId.errors.required">Please select a
                                                                Guest</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Presenter</label>
                                                        <!-- <select class="form-control" id="validationCustom03" formControlName="PresenterId" [ngClass]="{'is-invalid': submit && form.PresenterId.errors}">
                                                            <option *ngFor="let cat of presenterlist" [value]="cat.GuestId">{{cat.Name}}</option>
                                                          </select> -->
                                                        <ng-select formControlName="PresenterId" [ngClass]="{ 'is-invalid': submit && form.PresenterId.errors}">
                                                            <ng-option *ngFor="let car of presenterlist" [value]="car.GuestId">{{car.Name}}</ng-option>
                                                        </ng-select>
                                                        <div *ngIf="submit && form.PresenterId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.PresenterId.errors.required">Please select a
                                                                Presenter.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">BackStage List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">

                                        <div class="table-responsive">
                                            <!-- <table id="tbl" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>#</th>
                                                        <th>Guest</th>
                                                        <th>Presenter</th>
                                                        <th>Event Name</th>
                                                        <th>Is Complete</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let g of datalist">
                                                        <td scope="row">
                                                            <button class="btn btn-success btn-sm" (click)="edituserbyid(g.BcId)"><i class="fas fa-edit"></i></button>
                                                        </td>
                                                        <td scope="row">
                                                            <button class="btn btn-danger btn-sm" (click)="deleteuser(g.BcId)"><i class="fas fa-window-close"></i></button>
                                                        </td>
                                                        <td>{{g.GuestName}}</td>
                                                        <td>{{g.PresenterName}}</td>
                                                        <td>{{g.EventName}}</td>
                                                        <td><button class="btn btn-primary btn-sm" (click)="updatestatus(g.BcId,g.IsComplete)"> {{g.IsComplete}}</button></td>
                                                    </tr>
                                                </tbody>
                                            </table> -->
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="BcId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="GuestName" caption="Guest Name" [width]="200" dataType="string">
                                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                                </dxi-column>

                                                <dxi-column dataField="PresenterName" caption="Presenter Name" [width]="200" dataType="string"></dxi-column>
                                                <dxi-column dataField="EventName" caption="Event Name" [width]="200" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>

                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mycomp">
                                                        <div *dxTemplate="let data of 'mycomp'">
                                                            <button class="btn btn-primary btn-sm" (click)="updatestatus(data)"> {{data.row.data.IsComplete}}</button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>