<div class="container-fluid">
    <app-pagetitle title="Sub-Category" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Sub-Category</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Sub-Category Name</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="SubcategoryName" placeholder="Sub-Category Name" [ngClass]="{'is-invalid': submit && form.SubcategoryName.errors}">
                                                        <div *ngIf="submit && form.SubcategoryName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.SubcategoryName.errors.required">Please provide
                                                                a
                                                                Sub-Category Name</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Event</label>
                                                        <select class="form-control" id="validationCustom02" formControlName="EventId" [ngClass]="{'is-invalid': submit && form.EventId.errors}" (change)="getcategory($event.target.value)">
                                                            <option *ngFor="let cat of eventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                                                          </select>

                                                        <div *ngIf="submit && form.EventId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventId.errors.required">Please select an
                                                                Event.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom03">User Type</label>
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="radio" name="UserType" value="User" formControlName="UserType" (change)="onchange($event.target.value)">
                                                            <label class="form-check-label" for="exampleRadios1">
                                                              User
                                                            </label>
                                                        </div>
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="radio" name="UserType" value="Guest" formControlName="UserType" (change)="onchange($event.target.value)">
                                                            <label class="form-check-label" for="exampleRadios1">
                                                              Guest
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>


                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Category</label>
                                                        <select class="form-control" id="validationCustom03" formControlName="CategoryId" [ngClass]="{'is-invalid': submit && form.CategoryId.errors}">
                                                            <option *ngFor="let cat of catlist" [value]="cat.CategoryId">{{cat.CategoryName}}</option>
                                                          </select>

                                                        <div *ngIf="submit && form.CategoryId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.CategoryId.errors.required">Please select a
                                                                Category.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Sub-Category List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <!-- <form class="needs-validation" (submit)="searchterm()" [formGroup]="searchform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Event</label>
                                                        <select class="form-control" id="validationCustom02" formControlName="EventId" [ngClass]="{'is-invalid': submit && f.EventId.errors}" (change)="getcategorysearch($event.target.value)">
                                                            <option>Select</option>
                                                            <option *ngFor="let cat of seventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                                                          </select>
                                                        <div *ngIf="f.EventId.errors" class="invalid-feedback">
                                                            <span *ngIf="f.EventId.errors.required">Please select an
                                                                Event.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="validationCustom03">User Type</label>
                                                        <select class="form-control" formControlName="UserType" aria-placeholder="Select UserType" (change)="onchangesearch($event.target.value)" [ngClass]="{'is-invalid': submit && f.UserType.errors}">
                                                            <option value="User">User</option>
                                                            <option value="Guest">Guest</option>
                                                        </select>
                                                        <div *ngIf="f.UserType.errors" class="invalid-feedback">
                                                            <span *ngIf="f.UserType.errors.required">Please select a
                                                                User Type.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-3">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Category</label>
                                                        <select class="form-control" id="validationCustom03" formControlName="CategoryId">
                                                            <option *ngFor="let cat of scatlist" [value]="cat.CategoryId">{{cat.CategoryName}}</option>
                                                          </select>

                                                    </div>
                                                </div>

                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-primary" type="submit" [disabled]='searchform.invalid'>Search</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-success" type="button" (click)="exporttoexcel()" [disabled]='searchform.invalid'>Excel</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-danger" type="button" (click)="reset()">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form> -->
                                        <div class="table-responsive">
                                            <!-- <table id="tbl" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>#</th>
                                                        <th>Sub-Category Name</th>
                                                        <th>Category Name</th>
                                                        <th>Event Name</th>
                                                        <th>User Type</th>
                                                        <th>Is Active</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let g of datalist">
                                                        <td scope="row">
                                                            <button class="btn btn-success btn-sm" (click)="edituserbyid(g.SubcategoryId)"><i class="fas fas fa-edit"></i></button>
                                                        </td>
                                                        <td scope="row">
                                                            <button class="btn btn-danger btn-sm" (click)="deleteuser(g.SubcategoryId)"><i class="fas fas fa-window-close"></i></button>
                                                        </td>
                                                        <td>{{g.SubcategoryName}}</td>
                                                        <td>{{g.CategoryName}}</td>
                                                        <td>{{g.EventName}}</td>
                                                        <td>{{g.UserType}}</td>
                                                        <td>{{g.IsActive}}</td>
                                                    </tr>
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input type="text" placeholder="SubcategoryName" name="SubcategoryName" /></td>
                                                        <td><input type="text" placeholder="CategoryName" name="CategoryName" /></td>
                                                        <td><input type="text" placeholder="EventName" name="EventName" /></td>
                                                        <td><input type="text" placeholder="UserType" name="UserType" /></td>
                                                        <td></td>
                                                    </tr>
                                                </tfoot>
                                            </table> -->
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="SubcategoryName" caption="SubCategory Name" [width]="200" dataType="string">
                                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="EventName" caption="Event Name" [width]="300" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="UserType" caption="User Type" [width]="100" dataType="string"></dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="saleAmountHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>

                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>
</div>