<div class="container-fluid">
    <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row" *ngIf="admin">
        <div class="col-xl-4">
            <div class="row">
                <div *ngFor="let stat of dashdata" class="col-md-12">
                    <app-stat [title]="stat.CategoryName" [invalue]="stat.GuestIn" [outvalue]="stat.GuestOut" [totvalue]="stat.TotalGuest" [eventid]="stat.EventId" [categoryid]="stat.CategoryId"></app-stat>
                </div>
            </div>
        </div>
        <div class="col-xl-8">
            <div class="card">
                <div class="card-body">
                    <!-- <table id="tbl" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                        <thead>
                            <tr>
                                <th>GuestName</th>
                                <th>Date & time</th>
                                <th>Status</th>
                                <th>Category</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let g of datalist">
                                <td>{{g.GuestName}}</td>
                                <td>{{g.CreatedOn | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                                <td>{{g.Status}}</td>
                                <td>{{g.CategoryName}}</td>

                            </tr>
                        </tbody>
                    </table> -->
                    <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true">
                        <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>

                        <dxi-column dataField="GuestName" caption="Guest Name" [width]="200" dataType="string">
                            <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                        </dxi-column>
                        <dxi-column dataField="CreatedOn" caption="Date & time" [width]="150" dataType="datetime">
                            <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                        </dxi-column>
                        <dxi-column dataField="Status" caption="Status" [width]="100" dataType="string"></dxi-column>
                        <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                            <!-- <dxo-header-filter [dataSource]="saleAmountHeaderFilter"></dxo-header-filter> -->
                        </dxi-column>

                        <dxo-pager>
                            [showNavigationButtons]="true" [showInfo]= "true"
                        </dxo-pager>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="superadmin">
        <div class="col-xl-12">
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="validationCustom02">Event</label>
                        <select class="form-control" id="validationCustom02" formControlName="EventId" (change)="getdashboardbyEventId($event.target.value)">
                        <option>Select</option>
                        <option *ngFor="let cat of eventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                      </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngFor="let stat of dashdata" class="col-md-4">
                    <app-stat [title]="stat.CategoryName" [invalue]="stat.GuestIn" [outvalue]="stat.GuestOut" [totvalue]="stat.TotalGuest" [eventid]="stat.EventId" [categoryid]="stat.CategoryId"></app-stat>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="addrole">
        <div class="col-xl-12">
            <div class="row">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="addform">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="validationCustom01"> Guest</label>
                                            <ng-select formControlName="GuestId" [ngClass]="{ 'is-invalid': submit && f.GuestId.errors} " (change)="getimage($event)">
                                                <ng-option *ngFor="let car of glist" [value]="car.GuestId">{{car.Name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submit && f.GuestId.errors " class="invalid-feedback ">
                                                <span *ngIf="f.GuestId.errors.required ">Please Select
                                                                a 
                                                                Guest Name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <div style="width:100%;"><label for="validationCustom23 ">Guest Image</label></div>
                                            <div style="width:100%;">
                                                <img class="img-fluid" [src]="logoimgsrc" /></div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-primary " type="submit " [disabled]='addform.invalid'>Save</button>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-body">
                            <!-- <table id="addlog" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                                <thead>
                                    <tr>
                                        <th>GuestName</th>
                                        <th>Date & time</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let g of loglist">

                                        <td>{{g.GuestName}}</td>
                                        <td>{{g.CreatedOn | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                                        <td>{{g.Status}}</td>
                                    </tr>
                                </tbody>
                            </table> -->
                            <dx-data-grid id="gridContainers" [dataSource]="loglist" keyExpr="LogId" [showBorders]="true">
                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>

                                <dxi-column dataField="GuestName" caption="Guest Name" [width]="200" dataType="string">
                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                </dxi-column>
                                <dxi-column dataField="CreatedOn" caption="Date & time" [width]="200" dataType="datetime">
                                    <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                                </dxi-column>
                                <dxi-column dataField="Status" caption="Status" [width]="100" dataType="string"></dxi-column>


                                <dxo-pager>
                                    [showNavigationButtons]="true" [showInfo]= "true"
                                </dxo-pager>
                            </dx-data-grid>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="viewrole">
        <div class="col-xl-12">
            <div class="row">
                <div class="col-md-6 col-xl-4 col-sm-12 col-xxl-4 offset-md-3 offset-xl-4 offset-xxl-4" *ngFor="let n of achorlist">
                    <div class="card">
                        <img class="card-img-top img-fluid" src="{{n.GuestImage}}" alt="Card image cap">
                        <div class="card-body">
                            <h4 class="card-title mt-0">{{n.GuestName}}</h4>
                            <p class="card-text">{{n.AboutGuest}}</p>
                        </div>
                        <div class="card-footer">
                            <h6 class="card-subtitle font-14 text-muted">Presenter</h6>
                            <h4 class="card-title">{{n.PresenterName}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>