import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthenticationService } from '../../core/services/auth.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { LogService } from '../../core/services/log.service';
import { EventservService } from '../../core/services/eventserv.service';
import { GuestService } from '../../core/services/guest.service';
import { BackstageService } from '../../core/services/backstage.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  DxDataGridComponent
} from 'devextreme-angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

/**
 * Dashboard Component
 */
export class DashboardComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;
  userflag: any;
  dashdata: any = [];
  eventlist: any = [];
  validationform: FormGroup;
  addform: FormGroup;
  superadmin: any = false;
  admin: any = false;
  addrole: any = false;
  viewrole: any = false;
  datalist = [];
  glist = [];
  loglist = [];
  achorlist = [];
  dtOptions: any = {};
  logoimgsrc: any;
  submit: boolean;
  constructor(public formBuilder: FormBuilder,
    private authservice: AuthenticationService,
    private tokenservice: TokenStorageService,
    private router: Router,
    private toastrService: ToastrService,
    private logService: LogService,
    private eventService: EventservService,
    private guestService: GuestService,
    private backstageService: BackstageService) {
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;

  }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  get form() {
    return this.validationform.controls;
  }

  get f() {
    return this.addform.controls;
  }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Dashboard', active: true }];
    this.userflag = this.tokenservice.getUser();
    console.log(this.userflag);
    this.validationform = this.formBuilder.group({
      EventId: ['']
    });

    this.addform = this.formBuilder.group({
      GuestId: ['', [Validators.required]],
      CategoryId: [this.userflag.CategoryId],
      EventId: [this.userflag.EventId],
      UserId: [this.userflag.id]
    });
   
    
    //this.bind();
    this.getnotification();
    this.getdashboard();
    this.binddata();
    if (this.userflag.roles == "Super Admin") {
      this.admin = false;
      this.superadmin = true;
      this.addrole = false;
      this.viewrole = false;
    }
    else if (this.userflag.roles == "Admin"){
      this.admin = true;
      this.superadmin = false;
      this.addrole = false;
      this.viewrole = false;
    }
    else{
      if(this.userflag.IsWrite == "Yes"){
        this.admin = false;
        this.superadmin = false;
        this.addrole = true;
        this.viewrole = false;
        this.getdata();
      }
      else{
        this.admin = false;
        this.superadmin = false;
        this.addrole = false;
        this.viewrole = true;
        this.getanchordata();
      }
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };
  }

  bind() {
    var t = { token: this.userflag.accessToken };
    this.authservice.checksession(t).subscribe(
      (data: any) => {
        if (data.message == "jwt expired") {
          this.authservice.logout();
          this.router.navigate(['/account/login']);
        }
      },
      (err: any) => {

      }
    );
    setTimeout(() => {
      this.bind();
    }, 60000);
  }

  binddata() {
    this.eventService.getevent().subscribe(
      (data: any) => {
        this.eventlist = data;
      },
      (err: any) => {

      }
    );
  }


  getnotification() {
    if (this.userflag.roles == "Admin") {
      this.logService.getlastlog(this.userflag.roles == "Super Admin" ? 0 : this.userflag.EventId).subscribe(
        (data: any) => {
          var t = data;
          if (t != null && t != undefined && t != '') {
            this.toastrService.info(t[0].GuestName + ' is ' + t[0].Status + ' at ' + t[0].CategoryName);
          }
        },
        (err: any) => {

        }
      );
      setTimeout(() => {
        this.getnotification();
      }, 10000);
    }
  }

  getdashboard() {
    if (this.userflag.roles == "Super Admin") { }
    else if (this.userflag.roles == "Admin") {
      this.logService.getdashboard(this.userflag.EventId).subscribe(
        (data: any) => {
          this.dashdata = data;
        },
        (err: any) => {

        }
      );
      var datatable = $('#tbl').DataTable();
      console.log(datatable);
      datatable.destroy();
      this.logService.getlogbyEventId(this.userflag.EventId).subscribe(
        (data: any) => {
          this.datalist = data;
        },
        (err: any) => {
  
        }
      );
    }
    setTimeout(() => {
      this.getdashboard();
    }, 10000);
  }

  getdashboardbyEventId(value) {
    this.logService.getdashboard(value).subscribe(
      (data: any) => {
        this.dashdata = data;
      },
      (err: any) => {

      }
    );

    setTimeout(() => {
      this.getdashboardbyEventId(value);
    }, 10000);
  }


  getdata() {
    this.guestService.GetGuestListBytype(this.userflag.CategoryId,this.userflag.EventId).subscribe(
      (data: any) => {
        this.glist = data;
      },
      (err: any) => {

      }
    );
    var datatable = $('#addlog').DataTable();
    console.log(datatable);
    datatable.destroy();
    this.logService.getlogbyUserId(this.userflag.id).subscribe(
      (data: any) => {
        this.loglist = data;
      },
      (err: any) => {

      }
    );

  }

  getanchordata() {
    this.backstageService.Getlistforanchor(this.userflag.EventId).subscribe(
      (data: any) => {
        this.achorlist = data;
      },
      (err: any) => {

      }
    );


  }

  getimage(value){
    console.log(value);
    var t = this.glist.filter(item => item.GuestId == value);
    this.logoimgsrc = 'http://eventapi.event.airconinfotech.com/uploads/guest/'+ t[0].GuestImage;
  }

  displayimage(value){
    alert(value);
    return 'http://eventapi.event.airconinfotech.com/uploads/guest/'+ value;
  }

  onSubmit(): void {
    this.submit = true;
      this.logService.addlog(this.addform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.logoimgsrc = '';
          this.getdata();
          this.addform.reset();
          this.addform.setValue(
            {
              GuestId: '',
              CategoryId: this.userflag.CategoryId,
              EventId: this.userflag.EventId,
              UserId: this.userflag.id
            }
          );
          //this.cleartext();
          
        },
        (err: any) => {

        }
      );
   
  }

}
