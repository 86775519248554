import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiModule } from '../shared/ui/ui.module';
import { WidgetModule } from '../shared/widget/widget.module';
import { PagesRoutingModule } from './pages-routing.module';

import { NgbNavModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import {  DxDataGridModule,DxCheckBoxModule,DxSelectBoxModule } from 'devextreme-angular';


import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { EventComponent } from './event/event.component';
import { UserComponent } from './user/user.component';
import { GuestComponent } from './guest/guest.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { UpdatelogComponent } from './updatelog/updatelog.component';
import { LoglistComponent } from './loglist/loglist.component';
import { LogbyeventComponent } from './logbyevent/logbyevent.component';
import { LogbyuserComponent } from './logbyuser/logbyuser.component';
import { LogbyguestComponent } from './logbyguest/logbyguest.component';
import { BackstageComponent } from './backstage/backstage.component';
import { CategorylistComponent } from './categorylist/categorylist.component';
import { SubcategorylistComponent } from './subcategorylist/subcategorylist.component';
import { GuestlistComponent } from './guestlist/guestlist.component';
import { UserlistComponent } from './userlist/userlist.component';
import { LogsdetailComponent } from './logsdetail/logsdetail.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 0.3
};

@NgModule({
  declarations: [DashboardComponent, CategoryComponent, SubcategoryComponent, EventComponent, UserComponent, GuestComponent, ChangepasswordComponent, UpdatelogComponent, LoglistComponent, LogbyeventComponent, LogbyuserComponent, LogbyguestComponent, BackstageComponent, CategorylistComponent, SubcategorylistComponent, GuestlistComponent, UserlistComponent, LogsdetailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PagesRoutingModule,
    UiModule,
    WidgetModule,
    NgbNavModule,
    NgbDropdownModule,
    NgbTooltipModule,
    PerfectScrollbarModule,
    DataTablesModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    DxDataGridModule,
    DxCheckBoxModule,
    DxSelectBoxModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class PagesModule { }
