<div class="container-fluid">
    <app-pagetitle title="Log Details" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mt-0"> Guest Check-In </h4>
                    <dx-data-grid id="gridContainers" [dataSource]="checkinlist" keyExpr="LogId" [showBorders]="true">
                        <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                        <dxi-column dataField="GuestImage" [width]="100" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column>
                        <dxi-column dataField="GuestName" caption="Guest Name" [width]="200" dataType="string">
                        </dxi-column>
                        <dxi-column dataField="CreatedOn" caption="Date & time" [width]="150" dataType="datetime">
                        </dxi-column>
                        <dxi-column dataField="Status" caption="Status" [width]="100" dataType="string"></dxi-column>
                        <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string"></dxi-column>
                        <dxi-column dataField="UserName" caption="Entry By" [width]="200" dataType="string"></dxi-column>
                        <dxo-pager>
                            [showNavigationButtons]="true" [showInfo]= "true"
                        </dxo-pager>
                        <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                        <div *dxTemplate="let data of 'cellTemplate'">
                            <img [src]="data.value" style="width:100px;" />
                        </div>
                    </dx-data-grid>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mt-0">Pending Guest Check-In </h4>
                    <dx-data-grid id="gridContainers" [dataSource]="pendinglist" keyExpr="GuestId" [showBorders]="true">
                        <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                        <dxi-column dataField="GImage" [width]="100" [allowFiltering]="false" [allowSorting]="false" cellTemplate="cellTemplate"></dxi-column>
                        <dxi-column dataField="Name" caption="Guest Name" [width]="200" dataType="string">
                        </dxi-column>
                        <dxi-column dataField="About" caption="About" [width]="200" dataType="datetime">
                        </dxi-column>
                        <dxo-pager>
                            [showNavigationButtons]="true" [showInfo]= "true"
                        </dxo-pager>
                        <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                        <div *dxTemplate="let data of 'cellTemplate'">
                            <img [src]="data.value" style="width:100px;" />
                        </div>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>