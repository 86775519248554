import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from '../../core/services/token-service.service';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/auth/';
//const AUTH_API = 'http://localhost:8080/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {

    constructor(private http: HttpClient,private tokenStorage: TokenStorageService) { }

  login(credentials: { username: any; password: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      Username: credentials.username,
      Password: credentials.password
    }, httpOptions);
  }

  register(user: { username: any; email: any; password: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    }, httpOptions);
  }

  checksession(credentials: { token: any;  }): Observable<any> {
    return this.http.post(AUTH_API + 'getsession', {
      token: credentials.token
    }, httpOptions);
  }

  changepassword(t: { LoginId: any;  Password: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'changepassword', {
      LoginId: t.LoginId,
      Password: t.Password
    }, httpOptions);
  }

  public logout(){
    var data = [];
    this.tokenStorage.saveUser(data);
  }
}