import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { CategoryService } from '../../core/services/category.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import * as XLSX from 'xlsx';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnDestroy, OnInit,AfterViewInit {
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;

  validationform: FormGroup;
  searchform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  eventlist = [];
  seventlist = [];
  temp = [];
  searchlist = [];
  columns = [
    { prop: 'CategoryName', name: 'Category Name' },
    { prop: 'EventName', name: 'Event Name' },
    { prop: 'UserType', name: 'User Type' },
    { prop: 'IsActive', name: 'Is Active' },
    { prop: 'IsRead', name: 'Is Read' },
    { prop: 'IsWrite', name: 'Is Write' }
  ];
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private eventService: EventservService,
    private router: Router,
    private tokenStorage: TokenStorageService) {
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;

     }

  // bread crumb items
  breadCrumbItems: Array<{}>;
  fileName = 'CategoryList.xlsx';
  submit: boolean;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Category', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      CategoryName: ['', [Validators.required]],
      EventId: ['', [Validators.required]],
      UserType: ['User', [Validators.required]],
      IsActive: [true],
      IsRead: [false],
      IsWrite: [false],
      CreatedBy: [this.userflag.id],
      CategoryId: 0
    });

    this.searchform = this.formBuilder.group({
      EventId: [0, [Validators.required]],
      UserType: ['']
    });



    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 25,
      processing: true
    };

    this.submit = false;
    this.getdata();

  }

  ngAfterViewInit(): void {
    // var datatable = $('#tbl').DataTable();
    // datatable.draw();
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);
    // datatable.destroy();
    // datatable.draw();
  }

  get form() {
    return this.validationform.controls;
  }

  get f() {
    return this.searchform.controls;
  }

  cleartext() {
    this.validationform.setValue({
      CategoryName: ['', [Validators.required]],
      EventId: ['', [Validators.required]],
      UserType: ['User', [Validators.required]],
      IsActive: [true],
      IsRead: [false],
      IsWrite: [false],
      CreatedBy: [this.userflag.id],
      CategoryId: 0
    });
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    // var datatable = $('#tbl').DataTable();
    // console.log(datatable);

    this.categoryService.getcategory('All').subscribe(
      (data: any) => {
        this.datalist = data;
        this.temp = data;
        // datatable.draw();
        // $.fn['dataTable'].ext.search.push((settings, data, dataIndex) => {
        //   const id = parseFloat(data[2]) || 0; // use data for the id column
        //   const usertype = data[4] || '';
        //   if (id > 0 && usertype != '' &&
        //     this.searchform.controls['EventId'].value == id && usertype == this.searchform.controls['UserType'].value) {
        //     return true;
        //   }
        //   return false;
        // });
      },
      (err: any) => {

      }
    );
    // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   // Destroy the table first
    //   dtInstance.destroy();
    //   // Call the dtTrigger to rerender again
    //   this.dtTrigger.next();
    // });

    this.eventService.getevent().subscribe(
      (data: any) => {
        this.eventlist = data;
        this.seventlist = data;
      },
      (err: any) => {

      }
    );
  }

  onSubmit(): void {
    this.submit = true;
    if (this.flag == 0) {

      this.categoryService.addcategory(this.validationform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.categoryService.updatecategory(this.validationform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    console.log(e);
    this.categoryService.findcategoryById(e.row.data.CategoryId).subscribe(
      (data: any) => {
        console.log(data);
        var f = data;
        this.validationform.setValue({
          CategoryName: f.CategoryName,
          EventId: f.EventId,
          UserType: f.UserType,
          IsActive: f.IsActive == 'Yes' ? true : false,
          IsRead: f.IsRead == 'Yes' ? true : false,
          IsWrite: f.IsWrite == 'Yes' ? true : false,
          CreatedBy: this.userflag.id,
          CategoryId: f.CategoryId
        });
        this.flag = 1;
        this.tabindex = 1;
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.categoryService.deletecategory(e.row.data.CategoryId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  updateFilter(event) {
    const value = event.target.value.toString().toLowerCase().trim();
    console.log(value);
    if (value != 'all') {
      // get the amount of columns in the table
      const count = this.columns.length;
      // get the key names of each column in the dataset
      const keys = Object.keys(this.temp[0]);
      console.log(count);
      // assign filtered matches to the active datatable
      this.datalist = this.temp.filter(item => {
        // iterate through each row's column data
        for (let i = 0; i < count; i++) {
          // check for a match
          if (
            (item[this.columns[i].prop] &&
              item[this.columns[i].prop]
                .toString()
                .toLowerCase()
                .indexOf(value) !== -1) ||
            !value
          ) {
            // found match, return true to add to result set
            return true;
          }
        }
      });
    }
    else {
      console.log(this.temp);
      this.datalist = this.temp;
    }

  }

  searchterm() {
    if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '') {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        // Destroy the table first
        dtInstance.destroy();
        // Switch
        this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
        // Call the dtTrigger to rerender again
        this.dtTrigger.next();
      });
      

    }
    else {

      this.datalist = this.temp;

    }
  }

  exporttoexcel() :void {
    if (this.searchform.controls['EventId'].value != '' && this.searchform.controls['UserType'].value != '') {

      this.datalist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
      this.searchlist = this.temp.filter(i => i.EventId == this.searchform.controls['EventId'].value && i.UserType == this.searchform.controls['UserType'].value);
      let lst = [];

      for(let i=0; i< this.searchlist.length; i++)
      {
          lst.push({ CategoryName: this.searchlist[i].CategoryName, EventName : this.searchlist[i].EventName, IsActive : this.searchlist[i].IsActive, IsRead : this.searchlist[i].IsRead, IsWrite : this.searchlist[i].IsWrite });
      }

      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lst);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
    else {

      this.datalist = this.temp;
      let lst = [];
      for(let i=0; i< this.datalist.length; i++)
      {
          lst.push({ CategoryName: this.datalist[i].CategoryName, EventName : this.datalist[i].EventName, IsActive : this.datalist[i].IsActive, IsRead : this.datalist[i].IsRead, IsWrite : this.datalist[i].IsWrite });
      }
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.datalist);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    }
  }

  filterById(): boolean {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.draw();
    });
    return false;
  }

  reset():void{
    this.searchform.reset();
    this.getdata();
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CategoryList.xlsx');
      });
    });
    e.cancel = true;
  }


}
