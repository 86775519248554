import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { TokenStorageService } from '../../core/services/token-service.service';
import { LogService } from '../../core/services/log.service';
import { GuestService } from '../../core/services/guest.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-logbyguest',
  templateUrl: './logbyguest.component.html',
  styleUrls: ['./logbyguest.component.scss']
})
export class LogbyguestComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;
  validationform: FormGroup;
  datalist = [];
  catlist =[];
  dtOptions: any = {};
  breadCrumbItems: Array<{}>;
  userflag:any;
  constructor(public formBuilder: FormBuilder,
    private guestService: GuestService,
    private tokenStorage: TokenStorageService, 
    private logService: LogService) {
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;
    }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Log by Guest', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      GuestId: ['', [Validators.required]],
    });
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.getdata();
  }

  get form() {
    return this.validationform.controls;
  }

  getdata() {
    this.guestService.GetGuestListbyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );

  }

  getlogdata(val){
    this.logService.getlogbyGuestId(val).subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LogByGuest.xlsx');
      });
    });
    e.cancel = true;
  }

}
