import { Injectable } from '@angular/core';
const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
userdata: any = [];
  constructor() { }

  signOut(): void {
    window.sessionStorage.setItem(TOKEN_KEY, '');
    window.sessionStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(USER_KEY, JSON.parse('{}'));
    localStorage.removeItem(USER_KEY);
    window.sessionStorage.clear();
    localStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return JSON.parse(localStorage.getItem(TOKEN_KEY) || '{}');
  }

  public saveUser(user: any): void {
    //window.sessionStorage.removeItem(USER_KEY);
    //window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    localStorage.removeItem(USER_KEY);
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    return JSON.parse(localStorage.getItem(USER_KEY) || '[]');
    //return JSON.parse(sessionStorage.getItem(USER_KEY) || '[]');
  }
}


