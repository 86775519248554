import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/event/';
//const AUTH_API = 'http://localhost:8080/api/event/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class EventservService {

  constructor(private http: HttpClient) { }

  addevent(t: { EventName: any; Venue: any; MobileNo: any; Orgenizer: any; DateofEvent: any; StartTime: any; EndTime: any; Location: any; Password: any; IsActive: any; CreatedBy: any; }, EventCode: any,EventImage: any, EventImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      EventName: t.EventName,
      EventCode: EventCode,
      Venue: t.Venue,
      MobileNo: t.MobileNo,
      Orgenizer: t.Orgenizer,
      DateofEvent: t.DateofEvent,
      StartTime: t.StartTime,
      EndTime: t.EndTime,
      Location: t.Location,
      EventImage: EventImage,
      Password: t.Password,
      IsActive: t.IsActive,
      CreatedBy: t.CreatedBy,
      EventImg: EventImg
    }, httpOptions);
  }

  updateevent(t: { EventId: any; EventName: any;  Venue: any; MobileNo: any; Orgenizer: any; DateofEvent: any; StartTime: any; EndTime: any; Location: any; Password: any; IsActive: any; CreatedBy: any; },EventCode: any,EventImage: any, EventImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'update', {
      EventId: t.EventId,
      EventName: t.EventName,
      EventCode: EventCode,
      Venue: t.Venue,
      MobileNo: t.MobileNo,
      Orgenizer: t.Orgenizer,
      DateofEvent: t.DateofEvent,
      StartTime: t.StartTime,
      EndTime: t.EndTime,
      Location: t.Location,
      EventImage: EventImage,
      Password: t.Password,
      IsActive: t.IsActive,
      CreatedBy: t.CreatedBy,
      EventImg: EventImg
    }, httpOptions);
  }

  getevent(): Observable<any> {
    return this.http.get(AUTH_API + 'get', httpOptions);
  }

  findeventById(EventId: number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?EventId=' + EventId, httpOptions);
  }

  deleteevent(EventId: number): Observable<any> {
    return this.http.get(AUTH_API + 'deleteevent?EventId=' + EventId, httpOptions);
  }

  geteventcode(): Observable<any> {
    return this.http.get(AUTH_API + 'geteventcode', httpOptions);
  }

  changepassword(t: { EventId: any;  Password: any; }): Observable<any> {
    return this.http.post(AUTH_API + 'changepassword', {
      EventId: t.EventId,
      Password: t.Password
    }, httpOptions);
  }

}
