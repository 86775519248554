<div class="container-fluid">
    <app-pagetitle title="Update Log" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01"> Guest</label>
                                    <ng-select formControlName="GuestId" [ngClass]="{ 'is-invalid': submit && form.GuestId.errors} " (change)="getimage($event)">
                                        <ng-option *ngFor="let car of datalist" [value]="car.GuestId">{{car.Name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submit && form.GuestId.errors " class="invalid-feedback ">
                                        <span *ngIf="form.GuestId.errors.required ">Please Select
                                                                a 
                                                                Guest Name</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-8 ">
                                <div class="form-group ">
                                    <label for="validationCustom23 ">Guest Image</label>
                                    <img [src]="logoimgsrc " style="width:100px; " />
                                </div>
                            </div>

                        </div>
                        <button class="btn btn-primary " type="submit " [disabled]='validationform.invalid'>Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>