<div class="container-fluid">
    <app-pagetitle title="Category" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add Category</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Category Name</label>
                                                        <input type="text" class="form-control" id="validationCustom01" formControlName="CategoryName" placeholder="Category Name" [ngClass]="{'is-invalid': submit && form.CategoryName.errors}">
                                                        <div *ngIf="submit && form.CategoryName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.CategoryName.errors.required">Please provide
                                                                a
                                                                Category Name</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Event</label>
                                                        <select class="form-control" id="validationCustom02" formControlName="EventId" [ngClass]="{'is-invalid': submit && form.EventId.errors}">
                                                            <option>Select</option>
                                                            <option *ngFor="let cat of eventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                                                          </select>

                                                        <div *ngIf="submit && form.EventId.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventId.errors.required">Please select an
                                                                Event.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom03">User Type</label>
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="radio" name="UserType" value="User" formControlName="UserType">
                                                            <label class="form-check-label" for="exampleRadios1">
                                                              User
                                                            </label>
                                                        </div>
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="radio" name="UserType" value="Guest" formControlName="UserType">
                                                            <label class="form-check-label" for="exampleRadios1">
                                                              Guest
                                                            </label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="IsRead">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Read
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" formControlName="IsWrite">
                                                            <label class="form-check-label" for="defaultCheck2">
                                                              Is Write
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck3">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Category List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <!-- <form class="needs-validation" (submit)="searchterm()" [formGroup]="searchform">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom02">Event</label>
                                                        <select class="form-control" id="validationCustom02" formControlName="EventId" [ngClass]="{'is-invalid': submit && f.EventId.errors}">
                                                            <option>Select</option>
                                                            <option *ngFor="let cat of seventlist" [value]="cat.EventId">{{cat.EventName}}</option>
                                                          </select>
                                                        <div *ngIf="f.EventId.errors" class="invalid-feedback">
                                                            <span *ngIf="f.EventId.errors.required">Please select an
                                                                Event.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="validationCustom03">User Type</label>
                                                        <select class="form-control" formControlName="UserType" aria-placeholder="Select UserType">
                                                            <option value="User">User</option>
                                                            <option value="Guest">Guest</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-primary" type="submit" [disabled]='searchform.invalid'>Search</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-success" type="button" (click)="exporttoexcel()" [disabled]='searchform.invalid'>Excel</button>
                                                    </div>
                                                </div>
                                                <div class="col-md-1">
                                                    <div class="form-group">
                                                        <br>
                                                        <button class="btn btn-danger" type="button" (click)="reset()">Reset</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form> -->
                                        <div class="table-responsive ">
                                            <!-- <table id="tbl" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>#</th>
                                                        <th>Category Name</th>
                                                        <th>Event Name</th>
                                                        <th>User Type</th>
                                                        <th>Is Active</th>
                                                        <th>Is Read</th>
                                                        <th>Is Write</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let g of datalist ">
                                                        <th scope="row ">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(g.CategoryId) "><i class="fas fas fa-edit "></i></button>

                                                        </th>
                                                        <th scope="row ">

                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(g.CategoryId) "><i class="fas fas fa-window-close "></i></button>
                                                        </th>
                                                        <td>{{g.CategoryName}}</td>
                                                        <td>{{g.EventName}}</td>
                                                        <td>{{g.UserType}}</td>
                                                        <td>{{g.IsActive}}</td>
                                                        <td>{{g.IsRead}}</td>
                                                        <td>{{g.IsWrite}}</td>
                                                    </tr>
                                                </tbody>
                                            </table> -->

                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true" (onExporting)="onExporting($event)">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="EventName" caption="Event Name" [width]="300" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="UserType" caption="User Type" [width]="100" dataType="string"></dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="saleAmountHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="IsRead" caption="Is Read" [width]="100" dataType="string"></dxi-column>
                                                <dxi-column dataField="IsWrite" caption="Is Write" [width]="100" dataType="string">
                                                    <!-- <dxo-header-filter [allowSearch]="true"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav "></div>
                </div>
            </div>
        </div>
    </div>
</div>