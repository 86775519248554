import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { CategoryService } from '../../core/services/category.service';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-userlist',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss']
})
export class UserlistComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes: any;
  datalist = [];
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private userService: UserProfileService,
    private eventService: EventservService,
    private router: Router,
    private tokenStorage: TokenStorageService) {this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key; }

  breadCrumbItems: Array<{}>;
  userflag :any;

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'User', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.getdata();
  }

  getdata() {

    this.userService.GetUserbyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'UserList.xlsx');
      });
    });
    e.cancel = true;
  }

}
