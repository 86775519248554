import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { GuestService } from '../../core/services/guest.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { LogService } from '../../core/services/log.service';
@Component({
  selector: 'app-updatelog',
  templateUrl: './updatelog.component.html',
  styleUrls: ['./updatelog.component.scss']
})
export class UpdatelogComponent implements OnInit {
  validationform: FormGroup;
  userflag: any;
  datalist = [];
  logoimgsrc: any;
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private guestService: GuestService,
    private router: Router,
    private tokenStorage: TokenStorageService,
    private logService: LogService) { }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Update Log', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      GuestId: ['', [Validators.required]],
      CategoryId: [this.userflag.CategoryId],
      EventId: [this.userflag.EventId],
      UserId: [this.userflag.id]
    });

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }


  getdata() {
    this.guestService.GetGuestListbyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );
  }

  getimage(value){
    console.log(value);
    var t = this.datalist.filter(item => item.GuestId == value);
    this.logoimgsrc = 'http://evenapi.event.airconinfotech.com/uploads/guest/'+ t[0].GuestImage;
  }

  onSubmit(): void {
    this.submit = true;
      this.logService.addlog(this.validationform.value).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.validationform.setValue(
            {
              GuestId: '',
              CategoryId: this.userflag.CategoryId,
              EventId: this.userflag.EventId,
              UserId: this.userflag.id
            }
          );
          //this.cleartext();
          this.logoimgsrc = '';
        },
        (err: any) => {

        }
      );
   
  }







}
