import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/user/';
//const AUTH_API = 'http://localhost:8080/api/user/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class UserProfileService {
    constructor(private http: HttpClient) { }

    adduser(t: { Name: any; UserName: any; Password: any; MobileNo: any; CategoryId: any; EventId :any; IsActive: any; CreatedBy: any; },username:any,UserImage:any,UserImg: any): Observable<any> {
      return this.http.post(AUTH_API + 'add', {
        Name: t.Name,
        UserName: username,
        Password: t.Password,
        MobileNo: t.MobileNo,
        CategoryId: t.CategoryId,
        EventId: t.EventId,
        IsActive: t.IsActive == true ? "Yes" : "No",
        CreatedBy: t.CreatedBy,
        UserImage: UserImage,
        UserImg: UserImg
      }, httpOptions);
    }
  
    updateuser(t: { UserId: any; Name: any; UserName: any; Password: any; MobileNo: any; CategoryId: any; EventId :any; IsActive: any; CreatedBy: any; },username:any,UserImage:any,UserImg: any): Observable<any> {
      return this.http.post(AUTH_API + 'update', {
        UserId: t.UserId,
        Name: t.Name,
        UserName: username,
        Password: t.Password,
        MobileNo: t.MobileNo,
        CategoryId: t.CategoryId,
        EventId: t.EventId,
        IsActive: t.IsActive == true ? "Yes" : "No",
        CreatedBy: t.CreatedBy,
        UserImage: UserImage,
        UserImg: UserImg
      }, httpOptions);
    }
  
    getuser(): Observable<any> {
      return this.http.get(AUTH_API + 'get', httpOptions);
    }
  
    finduserById(UserId: number): Observable<any> {
      return this.http.get(AUTH_API + 'findById?UserId=' + UserId, httpOptions);
    }
  
    deleteuser(UserId: number): Observable<any> {
      return this.http.get(AUTH_API + 'delete?UserId=' + UserId, httpOptions);
    }

    changepassworduser(t: { UserId: any;  Password: any; }): Observable<any> {
      return this.http.post(AUTH_API + 'changepassword', {
        UserId: t.UserId,
        Password: t.Password
      }, httpOptions);
    }

    GetUserbyEventId(EventId: number): Observable<any> {
      return this.http.get(AUTH_API + 'GetUserbyEventId?EventId=' + EventId, httpOptions);
    }
}
