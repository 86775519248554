<div class="container-fluid">
    <app-pagetitle title="Event" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <ul ngbNav #nav="ngbNav" [(activeId)]="tabindex" class="nav-tabs">
                        <li [ngbNavItem]="1">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="fas fa-pen-square"></i></span>
                                <span class="d-none d-sm-block">Add New Event</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">


                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Event Name </label>
                                                        <input type="text" class="form-control" formControlName="EventName" placeholder="Event Name" [ngClass]="{'is-invalid': submit && form.EventName.errors}">
                                                        <div *ngIf="submit && form.EventName.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventName.errors.required">Please provide
                                                                a
                                                                Event Name</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Event Code</label>
                                                        <input type="text" class="form-control" formControlName="EventCode" placeholder="EventCode" [ngClass]="{'is-invalid': submit && form.EventCode.errors}">
                                                        <div *ngIf="submit && form.EventCode.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventCode.errors.required">Please
                                                                provide
                                                                a
                                                                Event Code</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom04">Mobile No *</label>
                                                        <input type="text" class="form-control" id="validationCustom04" formControlName="MobileNo" placeholder="MobileNo" [ngClass]="{'is-invalid': submit && form.MobileNo.errors}">
                                                        <div *ngIf="submit && form.MobileNo.errors" class="invalid-feedback">
                                                            <span *ngIf="form.MobileNo.errors.required">Please provide a
                                                                MobileNo.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom05">Venue</label>
                                                        <input type="text" class="form-control" id="validationCustom05" formControlName="Venue" placeholder="Venue" [ngClass]="{'is-invalid': submit && form.Venue.errors}">
                                                        <div *ngIf="submit && form.Venue.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Venue.errors.required">Please provide a
                                                                valid
                                                                Venue.</span>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">Orgenizer</label>
                                                        <input type="text" class="form-control" formControlName="Orgenizer" id="Orgenizer" [ngClass]="{'is-invalid': submit && form.Orgenizer.errors}">

                                                        <div *ngIf="submit && form.Orgenizer.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Orgenizer.errors.required">Please provide
                                                                a
                                                                Orgenizer</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom04">Date of Event</label>
                                                        <input type="date" class="form-control" id="validationCustom04" formControlName="DateofEvent" placeholder="DateofEvent" [ngClass]="{'is-invalid': submit && form.DateofEvent.errors}">
                                                        <div *ngIf="submit && form.DateofEvent.errors" class="invalid-feedback">
                                                            <span *ngIf="form.DateofEvent.errors.required">Please
                                                                provide a
                                                                Date of Event.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">

                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom05">Start Time</label>
                                                        <input class="form-control" type="time" formControlName="StartTime" value="01:00:00" id="example-time-input" [ngClass]="{'is-invalid': submit && form.StartTime.errors}">
                                                        <div *ngIf="submit && form.StartTime.errors" class="invalid-feedback">
                                                            <span *ngIf="form.StartTime.errors.required">Please provide a
                                                                valid
                                                                Start Time.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom01">End Time</label>
                                                        <input class="form-control" type="time" formControlName="EndTime" value="01:00:00" id="example-time-input" [ngClass]="{'is-invalid': submit && form.EndTime.errors}">
                                                        <div *ngIf="submit && form.EndTime.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EndTime.errors.required">Please provide
                                                                a
                                                                EndTime</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom04">Location</label>
                                                        <input type="text" class="form-control" id="validationCustom04" formControlName="Location" placeholder="Location" [ngClass]="{'is-invalid': submit && form.Location.errors}">
                                                        <div *ngIf="submit && form.Location.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Location.errors.required">Please provide a
                                                                Location.</span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom05">Password</label>
                                                        <input type="text" class="form-control" id="validationCustom05" formControlName="Password" placeholder="Password" [ngClass]="{'is-invalid': submit && form.Password.errors}">
                                                        <div *ngIf="submit && form.Password.errors" class="invalid-feedback">
                                                            <span *ngIf="form.Password.errors.required">Please provide
                                                                a
                                                                valid
                                                                Password.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">
                                                        <label for="validationCustom23">Event Image</label>
                                                        <input #myInput type="file" class="form-control" id="validationCustom23" formControlName="EventImage" [ngClass]="{'is-invalid': submit && form.EventImage.errors}" (change)="onFileChange($event)" accept="image/png,image/jpg, image/jpeg">
                                                        <div *ngIf="submit && form.EventImage.errors" class="invalid-feedback">
                                                            <span *ngIf="form.EventImage.errors.required">Please provide a
                                                                valid
                                                                Event Image.</span>
                                                        </div>
                                                        <img [src]="logoimgsrc" style="width:100px;" />
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group">

                                                        <div class="form-check mb-3">
                                                            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" formControlName="IsActive">
                                                            <label class="form-check-label" for="defaultCheck1">
                                                              Is Active
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                            <button class="btn btn-primary" type="submit" [disabled]="validationform.invalid">Save</button>
                                        </form>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li [ngbNavItem]="2">
                            <a ngbNavLink>
                                <span class="d-block d-sm-none"><i class="far fa-list-alt"></i></span>
                                <span class="d-none d-sm-block">Event List</span>
                            </a>
                            <ng-template ngbNavContent>
                                <div class="card">
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <!-- <table id="tbl" class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>#</th>
                                                        <th>Event Code</th>
                                                        <th>Event Name</th>
                                                        <th>Mobile No</th>
                                                        <th>Venue</th>
                                                        <th>Is Active</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let g of datalist">
                                                        <td>
                                                            <button class="btn btn-success btn-sm" (click)="edituserbyid(g.EventId)"><i
                                                                class="fas fa-edit"></i></button>

                                                        </td>
                                                        <td>
                                                            <button class="btn btn-danger btn-sm" (click)="deleteuser(g.EventId)"><i
                                                                class="fas fas fa-window-close"></i></button>
                                                        </td>
                                                        <td>
                                                            {{g.EventCode}}
                                                        </td>
                                                        <td>{{g.EventName}}</td>
                                                        <td>{{g.MobileNo}}</td>
                                                        <td>{{g.Venue}}</td>
                                                        <td>{{g.IsActive}}</td>
                                                    </tr>
                                                </tbody>
                                            </table> -->
                                            <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="EventId" [showBorders]="true">
                                                <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                                                <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                                                <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="myCommand">
                                                        <div *dxTemplate="let data of 'myCommand'">
                                                            <button class="btn btn-success btn-sm " (click)="edituserbyid(data)"><i class="fas fas fa-edit "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column type="buttons" [width]="50">
                                                    <dxi-button template="mydel">
                                                        <div *dxTemplate="let data of 'mydel'">
                                                            <button class="btn btn-danger btn-sm " (click)="deleteuser(data) "><i class="fas fas fa-window-close "></i></button>
                                                        </div>
                                                    </dxi-button>
                                                </dxi-column>
                                                <dxi-column dataField="EventCode" caption="Event Code" [width]="100" dataType="string">
                                                    <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="EventName" caption="Event Name" [width]="300" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>
                                                <dxi-column dataField="MobileNo" caption="MobileNo" [width]="100" dataType="string"></dxi-column>
                                                <dxi-column dataField="Venue" caption="Venue" [width]="200" dataType="string"></dxi-column>
                                                <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                                    <!-- <dxo-header-filter [dataSource]="saleAmountHeaderFilter"></dxo-header-filter> -->
                                                </dxi-column>


                                                <dxo-pager>
                                                    [showNavigationButtons]="true" [showInfo]= "true"
                                                </dxo-pager>
                                                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                                            </dx-data-grid>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <div [ngbNavOutlet]="nav"></div>
                </div>
            </div>
        </div>
    </div>


</div>