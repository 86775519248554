<div class="container-fluid">
    <app-pagetitle title="Log by Event" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <!-- <table class="table table-bordered table-sm m-0" datatable [dtOptions]="dtOptions">
                        <thead>
                            <tr>
                                <th>GuestName</th>
                                <th>Date & time</th>
                                <th>Status</th>
                                <th>Category</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let g of datalist">
                                <td>{{g.GuestName}}</td>
                                <td>{{g.CreatedOn | date: 'dd/MM/yyyy hh:mm:ss'}}</td>
                                <td>{{g.Status}}</td>
                                <td>{{g.CategoryName}}</td>

                            </tr>
                        </tbody>
                    </table> -->
                    <dx-data-grid id="gridContainers" [dataSource]="datalist" keyExpr="LogId" [showBorders]="true">
                        <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>

                        <dxi-column dataField="GuestName" caption="Guest Name" [width]="200" dataType="string">
                            <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                        </dxi-column>
                        <dxi-column dataField="CreatedOn" caption="Date & time" [width]="200" dataType="datetime">
                            <!-- <dxo-header-filter [dataSource]="orderHeaderFilter"></dxo-header-filter> -->
                        </dxi-column>
                        <dxi-column dataField="Status" caption="Status" [width]="100" dataType="string"></dxi-column>
                        <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string"></dxi-column>


                        <dxo-pager>
                            [showNavigationButtons]="true" [showInfo]= "true"
                        </dxo-pager>
                        <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                    </dx-data-grid>
                </div>
            </div>
        </div>
    </div>
</div>