import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/guest/';
//const AUTH_API = 'http://localhost:8080/api/guest/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GuestService {

  constructor(private http: HttpClient) { }

  addguest(t: { Name: any; UserName: any; About: any;  CategoryId: any; EventId :any; SubCategoryId: any; CreatedBy: any; },GuestImage:any,GuestImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'add', {
      Name: t.Name,
      About: t.About,
      CategoryId: t.CategoryId,
      EventId: t.EventId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      CreatedBy: t.CreatedBy,
      GuestImage: GuestImage,
      GuestImg: GuestImg
    }, httpOptions);
  }

  updateguest(t: {GuestId:any; Name: any; UserName: any; About: any;  CategoryId: any; EventId :any; SubCategoryId: any; CreatedBy: any; },GuestImage:any,GuestImg: any): Observable<any> {
    return this.http.post(AUTH_API + 'update', {
      GuestId: t.GuestId,
      Name: t.Name,
      About: t.About,
      CategoryId: t.CategoryId,
      EventId: t.EventId,
      SubCategoryId: t.SubCategoryId != '' && t.SubCategoryId != undefined && t.SubCategoryId != null ? t.SubCategoryId : 0,
      CreatedBy: t.CreatedBy,
      GuestImage: GuestImage,
      GuestImg: GuestImg
    }, httpOptions);
  }

  getguest(): Observable<any> {
    return this.http.get(AUTH_API + 'get', httpOptions);
  }

  findguestById(GuestId : number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?GuestId='+ GuestId, httpOptions);
  }

  deleteguest(GuestId : number): Observable<any> {
    return this.http.get(AUTH_API + 'delete?GuestId='+ GuestId, httpOptions);
  }

  GetGuestListbyEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetGuestListbyEventId?EventId='+ EventId, httpOptions);
  }
  
  GetGuestListBytype(CategoryId : number,EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetGuestListBytype?CategoryId='+ CategoryId + '&EventId=' + EventId, httpOptions);
  }

}
