import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/category/';
//const AUTH_API = 'http://localhost:8080/api/category/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  addcategory(t: { CategoryName: any; IsActive: any; CreatedBy: any; EventId:any; UserType:any; IsRead:any; IsWrite: any;}): Observable<any> {
    return this.http.post(AUTH_API + 'addcategory', {
      CategoryName: t.CategoryName,
      EventId: t.EventId,
      UserType: t.UserType,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy,
      IsRead: t.IsRead == true ? "Yes" : "No",
      IsWrite: t.IsWrite == true ? "Yes" : "No"
    }, httpOptions);
  }

  updatecategory(t: {CategoryId:any;  CategoryName: any; IsActive: any;  CreatedBy: any; EventId:any; UserType:any;IsRead:any; IsWrite: any;}): Observable<any> {
    return this.http.post(AUTH_API + 'updatecategory', {
      CategoryId: t.CategoryId,
      CategoryName: t.CategoryName,
      EventId: t.EventId,
      UserType: t.UserType,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy,
      IsRead: t.IsRead == true ? "Yes" : "No",
      IsWrite: t.IsWrite == true ? "Yes" : "No"
    }, httpOptions);
  }

  getcategory(trantype:any): Observable<any> {
    return this.http.get(AUTH_API + 'getcategory?trantype='+trantype, httpOptions);
  }

  findcategoryById(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?CategoryId='+ CategoryId, httpOptions);
  }

  deletecategory(CategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'deletecategory?CategoryId='+ CategoryId, httpOptions);
  }

  GetCatbyEventId(EventId : number,UserType:any): Observable<any> {
    return this.http.get(AUTH_API + 'GetCatbyEventId?EventId='+ EventId+"&UserType="+ UserType, httpOptions);
  }
  
  GetCategorybyEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetCategorybyEventId?EventId='+ EventId, httpOptions);
  }

}
