import { Component, OnInit,ViewChild } from '@angular/core';
import { TokenStorageService } from '../../core/services/token-service.service';
import { LogService } from '../../core/services/log.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-logbyevent',
  templateUrl: './logbyevent.component.html',
  styleUrls: ['./logbyevent.component.scss']
})
export class LogbyeventComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;

  datalist = [];
  dtOptions: any = {};
  breadCrumbItems: Array<{}>;
  userflag:any;
  constructor(private tokenStorage: TokenStorageService, private logService: LogService) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
   }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Log by Event', active: true }];
    this.userflag = this.tokenStorage.getUser();

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.getdata();
  }

  getdata() {
    this.logService.getlogbyEventId(this.userflag.EventId).subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );

  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'LogbyEvent.xlsx');
      });
    });
    e.cancel = true;
  }

}
