<div class="container-fluid">
    <app-pagetitle title="Sub-Category" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="table-responsive">
                        <dx-data-grid id="gridContainer" [dataSource]="datalist" keyExpr="CategoryId" [showBorders]="true">
                            <dxo-filter-row [visible]="true" [applyFilter]="currentFilter"></dxo-filter-row>
                            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                            <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>

                            <dxi-column dataField="SubcategoryName" caption="SubCategory Name" [width]="200" dataType="string">
                                <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                            </dxi-column>
                            <dxi-column dataField="CategoryName" caption="Category Name" [width]="200" dataType="string">
                                <!-- <dxo-header-filter [groupInterval]="10000"></dxo-header-filter> -->
                            </dxi-column>
                            <!-- <dxi-column dataField="EventName" caption="Event Name" [width]="300" dataType="string">
                               
                            </dxi-column> -->
                            <dxi-column dataField="UserType" caption="User Type" [width]="100" dataType="string"></dxi-column>
                            <dxi-column dataField="IsActive" caption="Is Active" [width]="100" dataType="string">
                                <!-- <dxo-header-filter [dataSource]="saleAmountHeaderFilter"></dxo-header-filter> -->
                            </dxi-column>

                            <dxo-pager>
                                [showNavigationButtons]="true" [showInfo]= "true"
                            </dxo-pager>
                            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                        </dx-data-grid>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>