import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CategoryService } from '../../core/services/category.service';
import { UserProfileService } from '../../core/services/user.service';
import { TokenStorageService } from '../../core/services/token-service.service';
import { EventservService } from '../../core/services/eventserv.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('myInput') myInputVariable: any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;

  validationform: FormGroup;
  tabindex = 1;
  flag: number = 0;
  addflag: any = '';
  viewflag: any = 'active';
  userflag: any;
  datalist = [];
  catlist = [];
  eventlist = [];
  logoimgsrc: any;
  logoimg: any;
  //ngbNav: NgbNav;
  constructor(public formBuilder: FormBuilder,
    private categoryService: CategoryService,
    private userService: UserProfileService,
    private eventService: EventservService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private tokenStorage: TokenStorageService) { 
      this.applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];
      this.currentFilter = this.applyFilterTypes[0].key;
    }

  // bread crumb items
  breadCrumbItems: Array<{}>;

  submit: boolean;
  dtOptions: any = {};
  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'User', active: true }];
    this.userflag = this.tokenStorage.getUser();
    this.validationform = this.formBuilder.group({
      Name: ['', [Validators.required]],
      CategoryId: ['', [Validators.required]],
      EventId: ['', [Validators.required]],
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      MobileNo: ['', [Validators.required]],
      IsActive: [true],
      CreatedBy: [this.userflag.id],
      UserId: 0
    });
    this.validationform.controls['UserName'].disable();
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print'
      ]
    };

    this.submit = false;
    this.getdata();

  }

  get form() {
    return this.validationform.controls;
  }

  cleartext() {
    this.validationform.setValue({
      Name: ['', [Validators.required]],
      CategoryId: ['', [Validators.required]],
      EventId: ['', [Validators.required]],
      UserName: ['', [Validators.required]],
      Password: ['', [Validators.required]],
      MobileNo: ['', [Validators.required]],
      IsActive: [true],
      CreatedBy: [this.userflag.id],
      UserId: 0
    });
    this.validationform.controls['UserName'].disable();
    this.flag = 0;
    this.submit = false;
  }

  getdata() {
    var datatable = $('#tbl').DataTable();
    console.log(datatable);
    datatable.destroy();
    this.userService.getuser().subscribe(
      (data: any) => {
        this.datalist = data;
      },
      (err: any) => {

      }
    );

    this.categoryService.getcategory('User').subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );

    this.eventService.getevent().subscribe(
      (data: any) => {
        this.eventlist = data;
      },
      (err: any) => {

      }
    );
  }

  geteventcode(value) {
    if (this.validationform.controls['EventId'].value != null && this.validationform.controls['EventId'].value != undefined && this.validationform.controls['EventId'].value != '') {
      var id = this.validationform.controls["EventId"].value;
      var t = this.eventlist.filter(item => item.EventId == id);
      var username = t[0].EventCode + '_' + value;
      this.validationform.controls['UserName'].setValue(username);
    }
    else {
      alert('Please Select Event');
    }
  }

  onFileChange(event) {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.logoimg = this.generate() + '_' + file.name;
      reader.readAsDataURL(file);
      var img = new Image();
      reader.onload = () => {
        img.src = reader.result as string;
      };

      img.onload = () => {
        this.logoimgsrc = this.imageToDataUri(img, 500, 500);
      }

    }
  }

  imageToDataUri(img, width, height) {

    // create an off-screen canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    // set its dimension to target size
    canvas.width = width;
    canvas.height = height;

    // draw source image into the off-screen canvas:
    ctx.drawImage(img, 0, 0, width, height);

    // encode image to data-uri with base64 version of compressed image
    return canvas.toDataURL();
  }

  generate() {
    const characters = 'abcdefghijklmnopqrstuvwxyz1234567890ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < 10; i++) {
      result +=
        characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  onSubmit(): void {
    this.submit = true;
    var id = this.validationform.controls["EventId"].value;
    var t = this.eventlist.filter(item => item.EventId == id);
    var username = t[0].EventCode + '_' + this.validationform.controls["MobileNo"].value;
    this.validationform.controls['UserName'].setValue(username);
    console.log(this.validationform.controls['IsActive'].value);
    if (this.flag == 0) {
      this.userService.adduser(this.validationform.value,this.validationform.controls['UserName'].value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.validationform.controls['UserName'].disable();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
    else {
      this.userService.updateuser(this.validationform.value,this.validationform.controls['UserName'].value, this.logoimg, this.logoimgsrc).subscribe(
        (data: any) => {
          alert(data.message);
          this.getdata();
          this.validationform.reset();
          this.validationform.controls['UserName'].disable();
          this.myInputVariable.nativeElement.value = "";
          //this.cleartext();
          this.tabindex = 2;
        },
        (err: any) => {

        }
      );
    }
  }


  edituserbyid(e) {
    //console.log(id);
    this.userService.finduserById(e.row.data.UserId).subscribe(
      (data: any) => {
        //console.log(this.userflag);
        var f = data;
        this.validationform.setValue({
          Name: f.Name,
          CategoryId: f.CategoryId,
          EventId: f.EventId,
          UserName: f.UserName,
          Password: f.Password,
          MobileNo: f.MobileNo,
          IsActive: f.IsActive == "Yes" ? true : false,
          CreatedBy: this.userflag.id,
          UserId: f.UserId
        });
        this.flag = 1;
        this.tabindex = 1;
        this.logoimgsrc = this.sanitizer.bypassSecurityTrustUrl('http://eventapi.event.airconinfotech.com/uploads/user/'+ f.UserImage);
        
        //this.nav.select(1);
      },
      (err: any) => {

      }
    );
  }

  deleteuser(e) {
    this.userService.deleteuser(e.row.data.UserId).subscribe(
      (data: any) => {
        alert(data.message);
        this.getdata();
      },
      (err: any) => {

      }
    );
  }

  onchange(value) {

    this.categoryService.GetCatbyEventId(value, 'User').subscribe(
      (data: any) => {
        this.catlist = data;
      },
      (err: any) => {

      }
    );
    if (this.validationform.controls['MobileNo'].value != null && this.validationform.controls['MobileNo'].value != undefined && this.validationform.controls['MobileNo'].value != '') {
      var t = this.eventlist.filter(item => item.EventId == value);
      var username = t[0].EventCode + '_' + this.validationform.controls['MobileNo'].value;
      this.validationform.controls['UserName'].setValue(username);
    }

  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'UserList.xlsx');
      });
    });
    e.cancel = true;
  }

}
