import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { CategoryComponent } from './category/category.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { EventComponent } from './event/event.component';
import { UserComponent } from './user/user.component';
import { GuestComponent } from './guest/guest.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { UpdatelogComponent } from './updatelog/updatelog.component';
import { LoglistComponent } from './loglist/loglist.component';
import { LogbyuserComponent } from './logbyuser/logbyuser.component';
import { LogbyeventComponent } from './logbyevent/logbyevent.component';
import { LogbyguestComponent } from './logbyguest/logbyguest.component';
import { BackstageComponent } from './backstage/backstage.component';
import { CategorylistComponent } from './categorylist/categorylist.component';
import { SubcategorylistComponent } from './subcategorylist/subcategorylist.component';
import { UserlistComponent } from './userlist/userlist.component';
import { GuestlistComponent } from './guestlist/guestlist.component';
import { LogsdetailComponent } from './logsdetail/logsdetail.component';

const routes: Routes = [
    { path: '', component: DashboardComponent },
    { path: 'category', component: CategoryComponent },
    { path: 'subcategory', component: SubcategoryComponent },
    { path: 'event', component: EventComponent },
    { path: 'user', component: UserComponent },
    { path: 'guest', component: GuestComponent },
    { path: 'changepassword', component: ChangepasswordComponent },
    { path: 'updatelog', component: UpdatelogComponent },
    { path: 'loglist', component: LoglistComponent },
    { path: 'log', component: LogbyeventComponent },
    { path: 'logbyuser', component: LogbyuserComponent },
    { path: 'logbyguest', component: LogbyguestComponent },
    { path: 'backstage', component: BackstageComponent },
    { path: 'categorylist', component: CategorylistComponent },
    { path: 'subcategorylist', component: SubcategorylistComponent },
    { path: 'userlist', component: UserlistComponent },
    { path: 'guestlist', component: GuestlistComponent },
    { path: 'logdetails/:eventid/:categoryid', component: LogsdetailComponent }
    // { path: 'chat', component: ChatComponent },
    // { path: 'kanban-board', component: KanbanComponent },
    // { path: 'ecommerce', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
    // { path: 'email', loadChildren: () => import('./email/email.module').then(m => m.EmailModule) },
    // { path: 'pages', loadChildren: () => import('./utility/utility.module').then(m => m.UtilityModule) },
    //{ path: 'ui', loadChildren: () => import('./ui/ui.module').then(m => m.UIModule) },
    // { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
    // { path: 'charts', loadChildren: () => import('./chart/chart.module').then(m => m.ChartModule) },
    // { path: 'form', loadChildren: () => import('./form/form.module').then(m => m.FormModule) },
    // { path: 'tables', loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule) },
    // { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
