<div class="container-fluid">
    <app-pagetitle title="Change Password" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <form class="needs-validation" (ngSubmit)="onSubmit()" [formGroup]="validationform">
                        <!-- <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom01">Old Password</label>
                                    <input type="text" class="form-control" id="validationCustom01"
                                        formControlName="OldPassword" placeholder="Old Password"
                                        [ngClass]="{'is-invalid': submit && form.OldPassword.errors}">
                                    <div *ngIf="submit && form.OldPassword.errors" class="invalid-feedback">
                                        <span *ngIf="form.OldPassword.errors.required">Please provide
                                            a
                                            Old Password</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom02">New Password</label>
                                    <input type="text" class="form-control" id="validationCustom02" formControlName="NewPassword" placeholder="New Password" [ngClass]="{'is-invalid': submit && form.NewPassword.errors}">
                                    <div *ngIf="submit && form.NewPassword.errors" class="invalid-feedback">
                                        <span *ngIf="form.NewPassword.errors.required">Please provide a
                                            New Password.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="validationCustom03">Confirm Password</label>
                                    <input type="text" class="form-control" id="validationCustom03" formControlName="ConfirmPassword" placeholder="Confirm Password" [ngClass]="{'is-invalid': submit && form.ConfirmPassword.errors}">
                                    <div *ngIf="submit && form.ConfirmPassword.errors" class="invalid-feedback">
                                        <span *ngIf="form.ConfirmPassword.errors.required">Please provide a
                                            Password.</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary" type="submit" [disabled]='validationform.invalid'>Save</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>