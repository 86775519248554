import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router'; 
import { TokenStorageService } from '../../core/services/token-service.service';
import { LogService } from '../../core/services/log.service';
import { GuestService } from '../../core/services/guest.service';
import {
  DxDataGridComponent
} from 'devextreme-angular';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
// Our demo infrastructure requires us to use 'file-saver-es'. We recommend that you use the official 'file-saver' package in your applications.
import { exportDataGrid } from 'devextreme/exporter';

@Component({
  selector: 'app-logsdetail',
  templateUrl: './logsdetail.component.html',
  styleUrls: ['./logsdetail.component.scss']
})
export class LogsdetailComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  currentFilter: any;
  applyFilterTypes:any;

  checkinlist = [];
  pendinglist = [];
  dtOptions: any = {};
  breadCrumbItems: Array<{}>;
  userflag:any;
  CategoryId: any;
  EventId :any;
  constructor(private guestService: GuestService,
    private tokenStorage: TokenStorageService,
     private logService: LogService,
     private route: ActivatedRoute) {
    this.applyFilterTypes = [{
      key: 'auto',
      name: 'Immediately',
    }, {
      key: 'onClick',
      name: 'On Button Click',
    }];
    this.currentFilter = this.applyFilterTypes[0].key;
   }

  ngOnInit(): void {
    this.breadCrumbItems = [{ label: 'EVENT' }, { label: 'Log Details', active: true }];
    this.userflag = this.tokenStorage.getUser();
console.log(this.route.params);
    this.route.params.subscribe(
      params => {
        console.log(params);
        this.EventId =  params['eventid'];
        this.CategoryId = params['categoryid'];
      }
    )
    //this.CategoryId = this.route.params[0];

    this.getdata(this.CategoryId,this.EventId);
  }

  getdata(id :any,eventid:any) {
    this.logService.getloglistCheckin(eventid,id).subscribe(
      (data: any) => {
        this.checkinlist = data;
      },
      (err: any) => {

      }
    );

    this.guestService.GetGuestListBytype(id,eventid).subscribe(
      (data: any) => {
        this.pendinglist = data;
      },
      (err: any) => {

      }
    );

  }

  onExporting(e) {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Loglist.xlsx');
      });
    });
    e.cancel = true;
  }

}
