import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const AUTH_API = 'https://eventapi.airconinfotech.com/api/subcategory/';
//const AUTH_API = 'http://localhost:8080/api/subcategory/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SubcategoryService {

  constructor(private http: HttpClient) { }

  addsubcategory(t: { SubcategoryName: any; IsActive: any; CreatedBy: any; EventId:any; CategoryId:any; UserType:any;}): Observable<any> {
    return this.http.post(AUTH_API + 'addsubcategory', {
      SubcategoryName: t.SubcategoryName,
      EventId: t.EventId,
      CategoryId: t.CategoryId,
      UserType: t.UserType,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy
    }, httpOptions);
  }

  updatesubcategory(t: {SubcategoryId:any;  SubcategoryName: any; IsActive: any;  CreatedBy: any; EventId:any;  CategoryId:any; UserType:any;}): Observable<any> {
    return this.http.post(AUTH_API + 'updatesubcategory', {
      SubcategoryId: t.SubcategoryId,
      CategoryId: t.CategoryId,
      SubcategoryName: t.SubcategoryName,
      EventId: t.EventId,
      UserType: t.UserType,
      IsActive: t.IsActive == true ? "Yes" : "No",
      CreatedBy:t.CreatedBy
    }, httpOptions);
  }

  getsubcategory(): Observable<any> {
    return this.http.get(AUTH_API + 'getsubcategory', httpOptions);
  }

  findsubcategoryById(SubcategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'findById?SubcategoryId='+ SubcategoryId, httpOptions);
  }

  deletesubcategory(SubcategoryId : number): Observable<any> {
    return this.http.get(AUTH_API + 'deletesubcategory?SubcategoryId='+ SubcategoryId, httpOptions);
  }

  GetSubcatbyCatId(CategoryId : number,UserType:any): Observable<any> {
    return this.http.get(AUTH_API + 'GetSubcatbyCatId?CategoryId='+ CategoryId+"&UserType="+ UserType, httpOptions);
  }
  
  GetSubCatByEventId(EventId : number): Observable<any> {
    return this.http.get(AUTH_API + 'GetSubCatByEventId?EventId='+ EventId, httpOptions);
  }
}
